import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import Logo from "../../images/logo.png"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">
          <img className="sidebar-logo" src={Logo} alt="Box Pack N Move" />
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">About</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Services</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Contact Us</Link>
      </Nav.Item>
    </Nav>
  </div>
)
